.StatisticWidget-module_widgets_igXo5__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.PlayersStatisticWidget-module_widget_VO4UY__Stat {
  background: var(--bg-block);
  border-radius: 10px;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  display: flex;
}

.PlayersTable-module_table_5Dqap__Stat {
  border: 1px solid var(--line-line);
  border-radius: 10px;
  flex-direction: column;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.PlayersTableHeader-module_header_-U0YH__Stat {
  background: var(--bg-component);
  justify-content: space-between;
  height: 40px;
  padding: 0 10px;
  display: flex;
}

.PlayersTableHeader-module_column_1kie5__Stat {
  justify-content: center;
  align-items: center;
  min-width: 15px;
  display: flex;
}

.PlayersTableHeader-module_column__text_YXzzt__Stat {
  color: var(--text-primary);
}

.PlayersTableLine-module_table-line_ysBMV__Stat {
  grid-column-gap: 10px;
  background: var(--bg-block);
  border-top: 1px solid var(--line-line);
  grid-template-columns: 1fr 1fr 15px;
  height: 40px;
  padding: 0 10px;
  display: grid;
}

.PlayersTableLine-module_table-line__sportsman_O9ujT__Stat {
  align-items: center;
  gap: 10px;
  min-width: 150px;
  display: flex;
}

.PlayersTableLine-module_table-line__sportsman__logo_-HuDo__Stat {
  border-radius: 50%;
}

.PlayersTableLine-module_table-line__sportsman__text_Cpns8__Stat {
  -webkit-line-clamp: 1;
  word-break: break-all;
  color: var(--text-primary);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.PlayersTableLine-module_table-line__team_gpDwO__Stat {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.PlayersTableLine-module_table-line__team__text_BAJp-__Stat {
  -webkit-line-clamp: 1;
  word-break: break-all;
  color: var(--text-secondary);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.PlayersTableLine-module_table-line__metric_lZ6aN__Stat {
  justify-content: center;
  align-items: center;
  display: flex;
}

.PlayersTableLine-module_table-line__metric__text_lYQNf__Stat {
  color: var(--text-primary);
}

.TournamentsStatisticWidget-module_widget_eKDuu__Stat {
  background: var(--bg-block);
  border-radius: 10px;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  display: flex;
}

.TournamentsStatisticWidget-module_widget__title_-pF-s__Stat {
  color: var(--text-primary);
  padding-left: 10px;
}

